<template>
  <PageContent
    custom-container-class="tw-overflow-hidden"
  >
    <OpenSnowHeroBlock
      class="tw-mb-20 lg:tw-mb-36"
      :show-action-buttons="showActionButtons"
    />
    <SellingPointFeature
      v-for="(sellingPointFeature, index) in sellingPointFeatures"
      :key="sellingPointFeature"
      class="tw-mb-20 lg:tw-mb-36"
      :align-image-right="index % 2 === 0"
      :background-image-cover="index === 5"
      :selling-point-feature="sellingPointFeature"
    />
    <div :class="allAccessBoxClass">
      <div class="container">
        <OpenSnowFeatures
          :header-class="featuresSectionHeadingClass"
        />
      </div>
    </div>
    <div :class="regularBoxClass">
      <div class="container">
        <OpenSnowReviews
          :header-class="sectionHeadingClass"
          :reviewers="reviewers"
        />
      </div>
    </div>
    <div :class="allAccessBoxClass">
      <div class="container">
        <OpenSnowVsOtherWeatherApps
          :header-class="featuresSectionHeadingClass"
        />
      </div>
    </div>
    <div :class="regularBoxClass">
      <div class="container">
        <FaqList
          class="lg:tw-w-8/12 tw-mx-auto"
          :has-body-padding="false"
          :header-class="sectionHeadingClass"
          :no-body-styles="true"
        />
      </div>
    </div>
    <div
      v-if="showActionButtons"
      class="container tw-mb-20"
    >
      <div class="lg:tw-w-1/2 tw-mx-auto">
        <ActionButtons
          :buy-now-button-type="buyNowButtonType"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { sellingPointFeatures } from '@@/components/FAQs/SellingPointFeature.vue';
import { reviewers } from '@@/components/FAQs/ReviewCard.vue';

export default {
  name: 'HomePageContent',

  props: {
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    allAccessBoxClass() {
      return `${this.regularBoxClass} all-access-background-color`;
    },

    buyNowButtonType() {
      return ButtonTypes.homePageBuyNowThemeable;
    },

    featuresSectionHeadingClass() {
      return `${this.sectionHeadingClass} tw-text-white`;
    },

    regularBoxClass() {
      return 'tw-pt-12 lg:tw-pt-20 tw-pb-1 lg:tw-pb-12';
    },

    sectionHeadingClass() {
      return 'tw-text-center tw-mb-10 tw-font-medium home-page-section-title';
    },

    sellingPointFeatures() {
      return [
        sellingPointFeatures.powerChasingTools,
        sellingPointFeatures.dailyAnalysis,
        sellingPointFeatures.trackStorms,
        sellingPointFeatures.forecastAnywhere,
        sellingPointFeatures.trustedByMillions,
        sellingPointFeatures.climateChange,
      ];
    },

    reviewers() {
      return [
        reviewers.pauline,
        reviewers.cody,
        reviewers.jon,
        reviewers.dale,
        reviewers.nikolai,
        reviewers.hope,
      ];
    },
  },
};
</script>
