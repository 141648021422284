<template>
  <div :class="containerClass">
    <div :class="imageContainerClass">
      <div
        :class="backgroundImageClass"
        :style="backgroundImageStyle"
      />
      <img
        v-if="appImage"
        :class="appImageClass"
        :src="appImage"
      >
    </div>
    <div :class="copyContainerClass">
      <h2 :class="headerClass">
        {{ headerText }}
      </h2>
      <p :class="copyClass">
        {{ copyText }}
      </p>
    </div>
  </div>
</template>

<script>
export const sellingPointFeatures = {
  climateChange: 'climateChange',
  dailyAnalysis: 'dailyAnalysis',
  forecastAnywhere: 'forecastAnywhere',
  powerChasingTools: 'powerChasingTools',
  trackStorms: 'trackStorms',
  trustedByMillions: 'trustedByMillions',
};

export default {
  name: 'SellingPointFeature',

  props: {
    alignImageRight: {
      type: Boolean,
      default: false,
    },
    backgroundImageCover: {
      type: Boolean,
      default: false,
    },
    sellingPointFeature: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(sellingPointFeatures).includes(value);
      },
    },
  },

  computed: {
    appImage() {
      if (this.isDailyAnalysis) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/dailysnow-colorado.png';
      }

      if (this.isForecastAnywhere) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/forecast-anywhere-map-norway.png';
      }

      if (this.isPowderChasingTools) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/favorite-locations-alta-stanton.png';
      }

      if (this.isTrackStorms) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/current-us-radar.png';
      }

      if (this.isTrustedByMillions) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/photo-powder-skier.png';
      }

      if (this.isClimateChange) {
        return 'https://blizzard.opensnow.com/app-images/500x1004/stripe-climate-change.png';
      }

      return null;
    },

    appImageClass() {
      return [
        'tw-absolute',
        this.alignImageRight ? 'tw-left-4 lg:tw-left-0' : 'tw-right-4 lg:tw-right-0',
        'tw-w-auto',
        this.$style.appImage,
      ];
    },

    backgroundImageClass() {
      return [
        'tw-absolute tw-top-0',
        'tw-h-full',
        'tw-bg-no-repeat',
        this.backgroundImageCover ? 'tw-bg-cover' : 'tw-bg-contain sm:tw-bg-cover',
        this.$style.backgroundImage,
        this.alignImageRight ? this.$style.backgroundImageRight : null,
      ];
    },

    backgroundImageStyle() {
      /* eslint global-require: off */

      const getBackgroundImage = () => {
        if (this.isClimateChange) {
          return 'https://blizzard.opensnow.com/images/home-page/climate-change.jpg';
        }

        if (this.isDailyAnalysis) {
          return 'https://blizzard.opensnow.com/images/home-page/daily-analysis.jpg';
        }

        if (this.isForecastAnywhere) {
          return 'https://blizzard.opensnow.com/images/home-page/forecast-anywhere.jpg';
        }

        if (this.isPowderChasingTools) {
          return 'https://blizzard.opensnow.com/images/home-page/powder-chasing-tools.jpg';
        }

        if (this.isTrackStorms) {
          return 'https://blizzard.opensnow.com/images/home-page/track-storms.jpg';
        }

        if (this.isTrustedByMillions) {
          return 'https://blizzard.opensnow.com/images/home-page/trusted-by-millions.jpg';
        }

        return null;
      };

      return {
        backgroundImage: `linear-gradient(var(--home-page-overlay-start), var(--home-page-overlay-end)), url(${getBackgroundImage()})`,
      };
    },

    containerClass() {
      return [
        'lg:tw-flex lg:tw-justify-evenly',
        this.$style.container,
      ];
    },

    copyClass() {
      return [
        'tw-px-4 tw-text-center',
        this.$style.copy,
      ];
    },

    copyContainerClass() {
      return [
        'tw-px-4 lg:tw-px-12',
        'lg:tw-self-stretch',
        this.alignImageRight ? 'tw-order-1' : 'tw-order-2',
        this.$style.copyContainer,
      ];
    },

    copyText() {
      if (this.isClimateChange) {
        return "Temperatures are warming and that's not good for snow. That's why we partner with Stripe to send 1% of our All-Access sales to innovative projects that directly remove carbon dioxide from the atmosphere and ensure its sequestration in secure, long-term storage. This alone will not solve climate change, but it's a start.";
      }

      if (this.isDailyAnalysis) {
        return 'Now you have a personal weather forecaster guiding you to the best conditions. Our local experts write a new "Daily Snow" forecast each day for regions around the US, Canada, Europe, Scandinavia, and across the Southern Hemisphere. Instead of spending hours sifting through weather and snow data, you can now get the inside scoop in just a few minutes.';
      }

      if (this.isForecastAnywhere) {
        return 'Our weather forecasts are available for any location on Earth, instantly. This means that you can view our weather forecasts for your favorite ski resort, backcountry ski location, camping destination, London, Denali, and even your home neighborhood. Save up to 15 custom locations to view on your Favorites screen for quick and convenient access to the latest 10-day weather forecast, estimated snow report, and more.';
      }

      if (this.isPowderChasingTools) {
        return 'Finding the location that has the best conditions can feel overwhelming. With OpenSnow, deciding where to go is easy. Compare the latest 10-day weather forecast, snow report & trail conditions, and mountain cams for your favorite locations in just a few seconds.';
      }

      if (this.isTrackStorms) {
        return 'We make it easy to track incoming storms with current radar, along with how much snow fell over the past 24 hours, season snowfall, and snow depth. You can also view forecast animations for radar, air quality, and wildfire smoke, as well as the latest avalanche forecast, active fire perimeter map, land ownership, and more.';
      }

      if (this.isTrustedByMillions) {
        return 'As skiing (and snowboarding) meteorologists, we started OpenSnow to make it easy for our friends and their friends to find powder. Now over 10 years later, we reach millions of people and still obsess about making the forecast more accurate and creating features to make finding powder easy. In other words, we love snow as much as you do.';
      }

      return null;
    },

    headerClass() {
      return [
        'tw-my-6 lg:tw-mt-0 lg:tw-mb-10',
        'tw-text-center tw-font-medium',
        'home-page-section-title',
      ];
    },

    headerText() {
      if (this.isClimateChange) {
        return 'Climate Change Commitment';
      }

      if (this.isDailyAnalysis) {
        return 'Read Daily Analysis From Local Forecasters, Worldwide';
      }

      if (this.isForecastAnywhere) {
        return 'Save Custom Forecasts Anywhere on Earth';
      }

      if (this.isPowderChasingTools) {
        return 'Compare Essential Weather Tools';
      }

      if (this.isTrackStorms) {
        return 'Track Storms With High-Resolution 3D Maps';
      }

      if (this.isTrustedByMillions) {
        return '10+ Years In, Trusted By Millions';
      }

      return null;
    },

    isClimateChange() {
      return this.sellingPointFeature === sellingPointFeatures.climateChange;
    },

    isDailyAnalysis() {
      return this.sellingPointFeature === sellingPointFeatures.dailyAnalysis;
    },

    isForecastAnywhere() {
      return this.sellingPointFeature === sellingPointFeatures.forecastAnywhere;
    },

    isPowderChasingTools() {
      return this.sellingPointFeature === sellingPointFeatures.powerChasingTools;
    },

    isTrackStorms() {
      return this.sellingPointFeature === sellingPointFeatures.trackStorms;
    },

    isTrustedByMillions() {
      return this.sellingPointFeature === sellingPointFeatures.trustedByMillions;
    },

    imageContainerClass() {
      return [
        'tw-relative',
        'lg:tw-self-stretch',
        this.alignImageRight ? 'tw-order-2' : 'tw-order-1',
        this.$style.imageContainer,
      ];
    },
  },
};
</script>

<style module>
.container {
  --home-page-overlay-start: rgb(255 255 255 / 20%);
  --home-page-overlay-end: rgb(255 255 255 / 40%);
}

[data-theme="dark"] .container,
[data-color-theme="dark"] .container {
  --home-page-overlay-start: rgb(0 0 0 / 20%);
  --home-page-overlay-end: rgb(0 0 0 / 40%);
}

@media (width >= 992px) {
  .container {
    min-height: 620px;
  }
}

@media (width >= 992px) {
  .copyContainer {
    width: 50%;
  }
}

@media (width >= 1200px) {
  .copyContainer {
    width: 30%;
  }
}

.copy {
  font-size: 1rem;
  line-height: 1.7;
}

@media (width >= 992px) {
  .copy {
    font-size: 1.3rem;
  }
}

.imageContainer {
  height: 350px;
}

@media (width >= 992px) {
  .imageContainer {
    height: auto;
    width: 50%;
  }
}

@media (width >= 1200px) {
  .imageContainer {
    width: 70%;
  }
}

.backgroundImage {
  background-position: 50%;
  left: 0;
  width: 100%;
  z-index: 1;
}

@media (width >= 992px) {
  .backgroundImage {
    width: 90%;
  }

  .backgroundImageRight {
    left: unset;
    right: 0;
  }
}

.appImage {
  height: 300px;
  top: calc(50% - 150px);
  z-index: 2;
}

@media (width >= 992px) {
  .appImage {
    height: 400px;
    top: calc(50% - 200px);
  }
}
</style>
